export const CREATE_CONVERSATION = "CONVERSATION/CREATE_CONVERSATION";
export const CREATE_CONVERSATION_DONE = "CONVERSATION/CREATE_CONVERSATION_DONE";

export const FCH_CONVERSATIONS = "CONVERSATION/FCH_CONVERSATIONS";
export const FCH_CONVERSATIONS_DONE = "CONVERSATION/FCH_CONVERSATIONS_DONE";

export const FCH_CONVERSATION = "CONVERSATION/FCH_CONVERSATION";
export const FCH_CONVERSATION_DONE = "CONVERSATION/FCH_CONVERSATION_DONE";

export const UPD_NAME = "CONVERSATION/UPD_NAME";
export const UPD_NAME_DONE = "CONVERSATION/UPD_NAME_DONE";

export const FCH_LOGS = "CONVERSATION/FCH_LOGS";
export const FCH_LOGS_DONE = "CONVERSATION/FCH_LOGS_DONE";

export const SEND_MESSAGE = "CONVERSATION/SEND_MESSAGE";
export const SEND_MESSAGE_DONE = "CONVERSATION/SEND_MESSAGE_DONE";
export const SEND_STREAM_MESSAGE = "CONVERSATION/SEND_STREAM_MESSAGE";
export const SEND_STREAM_MESSAGE_DONE = "CONVERSATION/SEND_STREAM_MESSAGE_DONE";
export const RECEIVE_MESSAGE = "CONVERSATION/RECEIVE_MESSAGE";
export const RECEIVE_MESSAGE_DONE = "CONVERSATION/RECEIVE_MESSAGE_DONE";
export const RECEIVE_MESSAGE_ERROR = "CONVERSATION/RECEIVE_MESSAGE_ERROR";
export const RECEIVE_STREAM_MESSAGE = "CONVERSATION/RECEIVE_STREAM_MESSAGE";
export const RECEIVE_STREAM_MESSAGE_DONE =
  "CONVERSATION/RECEIVE_STREAM_MESSAGE_DONE";
export const RECEIVE_STREAM_MESSAGE_ERROR =
  "CONVERSATION/RECEIVE_MESSAGE_ERROR";
export const STOP_STREAM_MESSAGE = "CONVERSATION/STOP_STREAM_MESSAGE";
export const STOP_BATCH_MESSAGE = "CONVERSATION/STOP_BATCH_MESSAGE";

export const DEL_CONVERSATION = "CONVERSATION/DEL_CONVERSATION";
export const DEL_CONVERSATION_DONE = "CONVERSATION/DEL_CONVERSATION_DONE";

export const CLEAR_CONVERSATION = "CONVERSATION/CLEAR_CONVERSATION";

export const CHANGE_NAME_EDITABLE = "CONVERSATION/CHANGE_NAME_EDITABLE";

export const RESET_CONVERSATION = "CONVERSATION/RESET_CONVERSATION";
export const RESET_CONVERSATION_DONE = "CONVERSATION/RESET_CONVERSATION_DONE";

export const SET_LOG_INDEX = "CONVERSATION/SET_LOG_INDEX";

export const GET_REFERENCE = "CONVERSATION/GET_REFERENCE";
export const GET_REFERENCE_DONE = "CONVERSATION/GET_REFERENCE_DONE";

export const SEL_SOURCES = "CONVERSATION/SEL_SOURCES";
export const SEARCH_SOURCES = "CONVERSATION/SEARCH_SOURCES";

export const LIKE_CONVERSATION = "CONVERSATION/LIKE_CONVERSATION";
export const LIKE_CONVERSATION_DONE = "CONVERSATION/LIKE_CONVERSATION_DONE";

export const DISLIKE_CONVERSATION = "CONVERSATION/DISLIKE_CONVERSATION";
export const DISLIKE_CONVERSATION_DONE =
  "CONVERSATION/DISLIKE_CONVERSATION_DONE";
