import { EditorBubble, useEditor } from "novel";
import PropTypes from "prop-types";
import React from "react";

const SelectorGroup = ({ children }) => {
  const { editor } = useEditor();

  return (
    <EditorBubble
      tippyOptions={{
        onHidden: () => {
          editor.chain().unsetHighlight().run();
        },
      }}
      className="flex w-fit max-w-[90vw] overflow-hidden rounded-md border border-muted bg-background shadow-xl"
    >
      {children}
    </EditorBubble>
  );
};

export default SelectorGroup;

SelectorGroup.propTypes = {
  children: PropTypes.node.isRequired,
};
