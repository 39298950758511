import { errorAlert } from "app/redux/actions/alert";
import { uploadImage } from "app/redux/actions/files";
import configureStore from "app/redux/store";
import { createImageUpload } from "novel/plugins";

export const uploadFn = createImageUpload({
  onUpload: (file) => configureStore.dispatch(uploadImage(file)),
  validateFn: (file) => {
    if (!file.type.includes("image/")) {
      configureStore.dispatch(errorAlert("File type not supported."));
      return false;
    } else if (file.size / 1024 / 1024 > 20) {
      configureStore.dispatch(errorAlert("File size too big (max 20MB)."));
      return false;
    }
    return true;
  },
});
