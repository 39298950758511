import Div from "@jumbo/shared/Div";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Stack, Typography } from "@mui/material";
import { healthCheck } from "app/redux/actions/app";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const HealthGroup = () => {
  const dispatch = useDispatch();

  const health = useSelector(
    ({ app }) => app.get("health"),
    (prev, next) => prev === next,
  );

  const fetchHealthCheck = useCallback(() => {
    dispatch(healthCheck());
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchHealthCheck();
    }, 30000);

    return () => clearInterval(interval);
  }, [fetchHealthCheck]);

  return (
    <Div sx={{ py: 2 }}>
      <Stack flexDirection="row" gap={1}>
        {health.map((item) => (
          <Stack
            key={item.name}
            justifyContent="space-between"
            alignItems="center"
            sx={{ border: "1px solid #c0c0c0", p: 1, borderRadius: 1 }}
          >
            {item.status === "UP" ? (
              <CheckCircleIcon sx={{ color: "green" }} />
            ) : (
              <CancelIcon sx={{ color: "red" }} />
            )}
            <Typography
              variant="body1"
              sx={{ color: item.status === "UP" ? "green" : "red" }}
            >
              {item.name}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Div>
  );
};

export default React.memo(HealthGroup);
