import ChannelPage from "app/pages/channel";
import ChannelDetailPage from "app/pages/channel/detail";
import React from "react";

const channelRoutes = [
  {
    path: "/channel",
    element: <ChannelPage />,
  },
  {
    path: "/channel/:id",
    element: <ChannelDetailPage />,
  },
];

export default channelRoutes;
