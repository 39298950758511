import http from "app/services";

import {
  GENERATE_REPORT,
  GENERATE_REPORT_FAILURE,
  GENERATE_REPORT_SUCCESS,
} from "../types/report";
import { statusHandler } from "./alert";

export const generateReport = (id, formData, signalKey) => {
  return async (dispatch) => {
    dispatch({ type: GENERATE_REPORT });
    const url = `/api/v1/users/${id}/reports`;

    const res = await http.stream(
      url,
      formData,
      (chunk) => {
        console.log(chunk);
      },
      signalKey,
    );

    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      dispatch({ type: GENERATE_REPORT_FAILURE });
      return false;
    }

    dispatch({ type: GENERATE_REPORT_SUCCESS });
    return true;
  };
};
