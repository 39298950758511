import { Check, Delete } from "@mui/icons-material";
import { Link as LinkIcon } from "@mui/icons-material";
import { Button, IconButton, Popover, styled, TextField } from "@mui/material";
import { useEditor } from "novel";
import PropTypes from "prop-types";
import React from "react";
import { useEffect, useRef, useState } from "react";

const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (_e) {
    return false;
  }
};

const getUrlFromString = (str) => {
  if (isValidUrl(str)) return str;
  try {
    if (str.includes(".") && !str.includes(" ")) {
      return new URL(`https://${str}`).toString();
    }
  } catch (_e) {
    return null;
  }
};

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}));

const LinkSelector = ({ open, onOpenChange, onClose }) => {
  const inputRef = useRef(null);
  const { editor } = useEditor();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  if (!editor) return null;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onOpenChange(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose("link");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const input = e.target[0];
    const url = getUrlFromString(input.value);
    if (url) {
      editor.chain().focus().setLink({ href: url }).run();
      handleClose();
    }
  };

  const handleRemoveLink = () => {
    editor.chain().focus().unsetLink().run();
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    handleClose();
  };

  return (
    <>
      <Button
        size="small"
        variant="text"
        onClick={handleClick}
        sx={{
          gap: 1,
          textTransform: "none",
          borderRadius: 0,
          border: "none",
          px: 1,
          "&:hover": {
            backgroundColor: "action.hover",
          },
        }}
        startIcon={<LinkIcon />}
      >
        Link
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            width: 280,
            marginTop: 1,
          },
        }}
      >
        <StyledForm onSubmit={handleSubmit}>
          <TextField
            inputRef={inputRef}
            size="small"
            placeholder="Paste a link"
            defaultValue={editor.getAttributes("link").href || ""}
            fullWidth
            sx={{
              "& .MuiInputBase-input": {
                px: 1,
                py: 0.5,
              },
            }}
            variant="outlined"
          />

          {editor.getAttributes("link").href ? (
            <IconButton
              size="small"
              onClick={handleRemoveLink}
              sx={{
                color: "error.main",
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          ) : (
            <IconButton size="small" type="submit" color="primary.main">
              <Check fontSize="small" />
            </IconButton>
          )}
        </StyledForm>
      </Popover>
    </>
  );
};

export default LinkSelector;

LinkSelector.propTypes = {
  open: PropTypes.bool.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
