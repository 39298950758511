import JumboIconButton from "@jumbo/components/JumboIconButton";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Card,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { getChannel } from "app/redux/actions/channel";
import ChannelConversationDataTable from "app/shared/DataTable/ChannelConversationDataTable";
import Loading from "app/shared/Loading";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

const EditKnowledgebase = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchParams = useParams();

  const user = useSelector(({ app }) => app.get("user"));
  const channelId = searchParams.id;
  const channel = useSelector(({ channel }) => {
    return channel.getIn(["results", channelId]);
  });

  useEffect(() => {
    dispatch(getChannel(channelId));
  }, [dispatch, channelId]);

  if (!channel || !user) return <Loading />;

  return (
    <>
      <Card sx={{ p: 4 }}>
        <Stack flexDirection="row" alignItems="center">
          <JumboIconButton onClick={() => navigate(-1)}>
            <ArrowBackOutlinedIcon />
          </JumboIconButton>
          <Typography variant="h3" sx={{ m: 0 }}>
            對話機器人設定
          </Typography>
        </Stack>

        <Divider sx={{ my: 4 }} />
        <Stack sx={{ width: { md: 500, sx: "100%" }, mb: 3 }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            名稱
          </Typography>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <TextField
              name="name"
              sx={{ width: "70%" }}
              placeholder="請輸入名稱"
              value={channel.get("name")}
              onChange={() => {}}
            />
            <Button variant="contained" onClick={() => {}}>
              更新名稱
            </Button>
          </Stack>
        </Stack>
        <Stack sx={{ width: { md: 500, sx: "100%" }, mb: 3 }}>
          <p>{channel.getIn(["metadata", "script"])}</p>
        </Stack>
        <ChannelConversationDataTable
          channelId={channelId}
          dataSource={channel.get("conversations", [])}
        />
      </Card>
    </>
  );
};

export default EditKnowledgebase;
