import AdminDashboardPage from "app/pages/admin/home";
import React from "react";
import { Navigate } from "react-router";

import adminKnowledgebaseRoutes from "./knowledgebase";
import adminMenuRoutes from "./menu";
// import adminModelRoutes from "./model";
import adminPluginRoutes from "./plugin";
import adminUserRoutes from "./user";

const adminDashBoardRoutes = [
  {
    path: "/admin/dashboard",
    element: <AdminDashboardPage />,
  },
  {
    path: "/admin",
    element: <Navigate to="/admin/dashboard" />,
  },
];

const adminRoutes = [
  ...adminDashBoardRoutes,
  ...adminKnowledgebaseRoutes,
  ...adminUserRoutes,
  // ...adminModelRoutes,
  ...adminMenuRoutes,
  ...adminPluginRoutes,
];

export default adminRoutes;
