import Div from "@jumbo/shared/Div";
import {
  Button,
  Divider,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { createChannel } from "app/redux/actions/channel";
import SelectSection from "app/shared/Form/SelectSection";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const CreateChannelModal = ({ uId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState({ value: "web", label: "網頁對話" });
  const [model, setModel] = useState(null);
  const [kbId, setKbId] = useState(null);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setName("");
  };

  const handleCreate = async () => {
    const doc = {
      name,
      type: type.value,
      metadata: {
        conversation_mode: "knowledgebase",
        language_model: model.label,
        language_model_id: model.value,
        output_mode: "streaming",
        knowledge_base_ids: [kbId.value],
      },
    };
    const id = await dispatch(createChannel(uId, doc));
    onClose();
    if (id) {
      navigate(`/knowledgebase/${id}`);
    }
  };

  const knowledgebaseList = useSelector(({ knowledgebase }) =>
    knowledgebase.getIn(["results", "knowledgebases"]),
  );
  const modelData = useSelector(({ model }) =>
    model.getIn(["results", "models"]),
  );
  if (!knowledgebaseList || !modelData) return null;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    pb: 1,
    borderRadius: 1,
  };
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Div sx={{ ...style }}>
          <Div sx={{ px: 3 }}>
            <Typography variant="h3">請輸入機器人名稱</Typography>
          </Div>

          <Divider sx={{ height: 1, mt: 2, mb: 3 }} />

          <Div sx={{ px: 3 }}>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              placeholder="名稱"
              variant="outlined"
            />
          </Div>
          <Div sx={{ px: 3, mt: 2 }}>
            <SelectSection
              name="type"
              defaultOptionLabel="選擇類型"
              value={type}
              onChange={(e) => setType(e.target.value)}
              options={[{ label: "網頁對話", value: "web" }]}
            />
          </Div>
          <Div sx={{ px: 3, mt: 2 }}>
            <SelectSection
              name="language_model"
              defaultOptionLabel="選擇語言模型"
              value={model}
              onChange={(e) => setModel(e.target.value)}
              options={modelData
                .map((model) => ({
                  value: model.get("id"),
                  label: model.get("name"),
                }))
                .toJS()}
            />
          </Div>
          <Div sx={{ px: 3, mt: 2 }}>
            <SelectSection
              name="knowledge_base_ids"
              defaultOptionLabel="選擇知識庫"
              value={kbId}
              onChange={(e) => {
                setKbId(e.target.value);
              }}
              options={knowledgebaseList
                .map((item) => ({
                  value: item.get("id"),
                  label: item.get("name"),
                }))
                .toJS()}
            />
          </Div>

          <Divider sx={{ height: 1, mt: 3, mb: 1 }} />

          <Div sx={{ px: 1 }}>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <Button variant="outlined" onClick={onClose}>
                取消
              </Button>
              <Button variant="contained" onClick={handleCreate}>
                建立
              </Button>
            </Stack>
          </Div>
        </Div>
      </Modal>
      <Button variant="contained" onClick={onOpen}>
        建立
      </Button>
    </>
  );
};

CreateChannelModal.propTypes = {
  uId: PropTypes.string.isRequired,
};

export default CreateChannelModal;
