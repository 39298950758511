import {
  Check as CheckIcon,
  CheckBox as CheckBoxIcon,
  Code as CodeIcon,
  FormatListNumbered as ListOrderedIcon,
  FormatQuote as QuoteIcon,
  KeyboardArrowDown as ChevronDownIcon,
  TextFields as TextIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Popover,
  styled,
} from "@mui/material";
import { useEditor } from "novel";
import PropTypes from "prop-types";
import React, { useState } from "react";

// 自定义的标题图标组件
const Heading1Icon = () => (
  <Box component="span" sx={{ fontSize: "12px", fontWeight: "bold" }}>
    H1
  </Box>
);

const Heading2Icon = () => (
  <Box component="span" sx={{ fontSize: "12px", fontWeight: "bold" }}>
    H2
  </Box>
);

const Heading3Icon = () => (
  <Box component="span" sx={{ fontSize: "12px", fontWeight: "bold" }}>
    H3
  </Box>
);

const items = [
  {
    name: "Text",
    icon: TextIcon,
    command: (editor) => editor.chain().focus().clearNodes().run(),
    isActive: (editor) =>
      editor.isActive("paragraph") &&
      !editor.isActive("bulletList") &&
      !editor.isActive("orderedList"),
  },
  {
    name: "Heading 1",
    icon: Heading1Icon,
    command: (editor) =>
      editor.chain().focus().clearNodes().toggleHeading({ level: 1 }).run(),
    isActive: (editor) => editor.isActive("heading", { level: 1 }),
  },
  {
    name: "Heading 2",
    icon: Heading2Icon,
    command: (editor) =>
      editor.chain().focus().clearNodes().toggleHeading({ level: 2 }).run(),
    isActive: (editor) => editor.isActive("heading", { level: 2 }),
  },
  {
    name: "Heading 3",
    icon: Heading3Icon,
    command: (editor) =>
      editor.chain().focus().clearNodes().toggleHeading({ level: 3 }).run(),
    isActive: (editor) => editor.isActive("heading", { level: 3 }),
  },
  {
    name: "To-do List",
    icon: CheckBoxIcon,
    command: (editor) =>
      editor.chain().focus().clearNodes().toggleTaskList().run(),
    isActive: (editor) => editor.isActive("taskItem"),
  },
  {
    name: "Bullet List",
    icon: ListOrderedIcon,
    command: (editor) =>
      editor.chain().focus().clearNodes().toggleBulletList().run(),
    isActive: (editor) => editor.isActive("bulletList"),
  },
  {
    name: "Numbered List",
    icon: ListOrderedIcon,
    command: (editor) =>
      editor.chain().focus().clearNodes().toggleOrderedList().run(),
    isActive: (editor) => editor.isActive("orderedList"),
  },
  {
    name: "Quote",
    icon: QuoteIcon,
    command: (editor) =>
      editor.chain().focus().clearNodes().toggleBlockquote().run(),
    isActive: (editor) => editor.isActive("blockquote"),
  },
  {
    name: "Code",
    icon: CodeIcon,
    command: (editor) =>
      editor.chain().focus().clearNodes().toggleCodeBlock().run(),
    isActive: (editor) => editor.isActive("codeBlock"),
  },
];

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: 24,
  minHeight: 24,
}));

const NodeSelector = ({ open, onOpenChange, onClose }) => {
  const { editor } = useEditor();
  const [anchorEl, setAnchorEl] = useState(null);

  if (!editor) return null;

  const activeItem = items.filter((item) => item.isActive(editor)).pop() ?? {
    name: "Multiple",
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onOpenChange(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose("node");
  };

  const handleItemClick = (item) => {
    item.command(editor);
    handleClose();
  };

  return (
    <>
      <Button
        size="small"
        variant="text"
        onClick={handleClick}
        endIcon={<ChevronDownIcon sx={{ width: 16, height: 16 }} />}
        sx={{
          minWidth: "auto",
          textTransform: "none",
          borderRadius: 0,
          px: 1,
          "&:hover": {
            backgroundColor: "action.hover",
          },
        }}
      >
        {activeItem.name}
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            width: 200,
            marginTop: 1,
            padding: 0.5,
          },
        }}
      >
        <List sx={{ p: 0 }}>
          {items.map((item) => (
            <StyledListItem
              key={item.name}
              onClick={() => handleItemClick(item)}
              disablePadding
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <IconWrapper>
                  <item.icon sx={{ fontSize: 16 }} />
                </IconWrapper>
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                />
              </Box>
              {activeItem.name === item.name && (
                <CheckIcon sx={{ width: 16, height: 16 }} />
              )}
            </StyledListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default NodeSelector;

NodeSelector.propTypes = {
  open: PropTypes.bool.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
