import { Functions as SigmaIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useEditor } from "novel";
import React from "react";

const MathSelector = () => {
  const { editor } = useEditor();

  if (!editor) return null;

  const handleClick = () => {
    if (editor.isActive("math")) {
      editor.chain().focus().unsetLatex().run();
    } else {
      const { from, to } = editor.state.selection;
      const latex = editor.state.doc.textBetween(from, to);

      if (!latex) return;

      editor.chain().focus().setLatex({ latex }).run();
    }
  };

  return (
    <Button
      variant="text"
      size="small"
      onClick={handleClick}
      sx={{
        minWidth: "48px",
        borderRadius: 0,
        px: 1,
        "&:hover": {
          backgroundColor: "action.hover",
        },
      }}
    >
      <SigmaIcon
        sx={{
          width: 12,
          height: 12,
          color: editor.isActive("math") ? "primary.main" : "inherit",
          strokeWidth: 2.3,
        }}
      />
    </Button>
  );
};

export default MathSelector;
