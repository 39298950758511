import { combineReducers } from "redux";

import appReducer from "./app";
import channelReducer from "./channel";
import conversationReducer from "./conversation";
import groupReducer from "./group";
import knowledgebaseReducer from "./knowledgebase";
import menuReducer from "./menu";
import modelReducer from "./model";
import pluginReducer from "./plugin";
import taskReducer from "./task";

const exportReducers = () => {
  return combineReducers({
    app: appReducer,
    channel: channelReducer,
    conversation: conversationReducer,
    knowledgebase: knowledgebaseReducer,
    group: groupReducer,
    model: modelReducer,
    menu: menuReducer,
    task: taskReducer,
    plugin: pluginReducer,
  });
};

export default exportReducers;
