import {
  Code as CodeIcon,
  FormatBold as BoldIcon,
  FormatItalic as ItalicIcon,
  FormatStrikethrough as StrikethroughIcon,
  FormatUnderlined as UnderlineIcon,
} from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useEditor } from "novel";
import React from "react";

const TextButtons = () => {
  const { editor } = useEditor();

  if (!editor) return null;

  const items = [
    {
      name: "bold",
      isActive: (editor) => editor.isActive("bold"),
      command: (editor) => editor.chain().focus().toggleBold().run(),
      icon: BoldIcon,
    },
    {
      name: "italic",
      isActive: (editor) => editor.isActive("italic"),
      command: (editor) => editor.chain().focus().toggleItalic().run(),
      icon: ItalicIcon,
    },
    {
      name: "underline",
      isActive: (editor) => editor.isActive("underline"),
      command: (editor) => editor.chain().focus().toggleUnderline().run(),
      icon: UnderlineIcon,
    },
    {
      name: "strike",
      isActive: (editor) => editor.isActive("strike"),
      command: (editor) => editor.chain().focus().toggleStrike().run(),
      icon: StrikethroughIcon,
    },
    {
      name: "code",
      isActive: (editor) => editor.isActive("code"),
      command: (editor) => editor.chain().focus().toggleCode().run(),
      icon: CodeIcon,
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      {items.map((item) => (
        <Button
          key={item.name}
          size="small"
          variant="text"
          onClick={() => item.command(editor)}
          sx={{
            minWidth: "auto",
            borderRadius: 0,
            padding: "4px 8px",
            "&:hover": {
              backgroundColor: "action.hover",
            },
          }}
        >
          <item.icon
            sx={{
              width: 16,
              height: 16,
              color: item.isActive(editor) ? "primary.main" : "inherit",
            }}
          />
        </Button>
      ))}
    </Box>
  );
};

export default TextButtons;
