import http from "app/services";

import {
  CREATE_CHANNEL,
  CREATE_CHANNEL_DONE,
  DEL_CHANNEL,
  DEL_CHANNEL_DONE,
  DEL_CONVERSATION,
  DEL_CONVERSATION_DONE,
  FCH_CHANNELS,
  FCH_CHANNELS_DONE,
  GET_CHANNEL,
  GET_CHANNEL_DONE,
} from "../types/channel";
import { statusHandler, successAlert } from "./alert";

export const fchChannels = (user_id) => {
  return async (dispatch) => {
    dispatch({ type: FCH_CHANNELS });
    const res = await http.get(`/api/v1/users/${user_id}/channels`);
    dispatch({ type: FCH_CHANNELS_DONE, payload: { doc: res.data } });
  };
};

export const getChannel = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_CHANNEL });
    const res = await http.get(`/api/v1/channels/${id}`);
    dispatch({ type: GET_CHANNEL_DONE, payload: res.data });
  };
};

export const delChannel = (id, user_id) => {
  return async (dispatch) => {
    dispatch({ type: DEL_CHANNEL });
    const res = await http.delete(`/api/v1/users/${user_id}/channels/${id}`);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({ type: DEL_CHANNEL_DONE, payload: { id } });
    dispatch(successAlert("刪除成功"));
  };
};

export const createChannel = (uid, doc) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_CHANNEL });
    const res = await http.post(`/api/v1/users/${uid}/channels`, doc);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({
      type: CREATE_CHANNEL_DONE,
      payload: res.data?.payload || {},
    });
    dispatch(successAlert("創建成功"));
  };
};

export const delConversation = (channel_id, conversation_id) => {
  return async (dispatch) => {
    dispatch({ type: DEL_CONVERSATION });
    const res = await http.delete(
      `/api/v1/channels/${channel_id}/conversations/${conversation_id}`,
    );
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({
      type: DEL_CONVERSATION_DONE,
      payload: { channel_id, conversation_id },
    });
    dispatch(successAlert("刪除成功"));
  };
};
