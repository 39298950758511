import ReportPage from "app/pages/report";
import React from "react";

const reportRoutes = [
  {
    path: "/report",
    element: <ReportPage />,
  },
];

export default reportRoutes;
