import styled from "@emotion/styled";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import Div from "@jumbo/shared/Div";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { errorAlert } from "app/redux/actions/alert";
import { uploadFile } from "app/redux/actions/files";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const DragUploadSection = ({
  position = "center",
  multiple = false,
  onFinish,
  error = false,
  helperText = "",
  tooltip = "",
  value,
  supportType = [],
}) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("INIT");
  const [path, setPath] = useState("");
  const fileInputRef = useRef(null);

  const supportTypeFilter = (type) => {
    switch (type) {
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "pptx":
        return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case "txt":
        return "text/plain";
      case "csv":
        return "text/csv";
      case "pdf":
        return "application/pdf";
      case "image":
        return "image/*";
      case "json":
        return "application/json";
      case "cpp":
      case "c":
      case "cs":
      case "py":
        return "text/plain";
      case "html":
        return "text/html";
      case "msg":
        return "application/vnd.ms-outlook";
      case "md":
        return "text/markdown";
      default:
        return type;
    }
  };

  const handleFileChange = async (e) => {
    const supportedTypes = supportType.map(supportTypeFilter);
    const files = Array.from(e.target.files);

    if (supportType.length > 0) {
      const isValidFile = multiple
        ? files.every((file) => supportedTypes.includes(file.type))
        : supportedTypes.includes(files[0].type);

      if (!isValidFile) {
        dispatch(errorAlert("不支援的檔案格式"));
        if (fileInputRef.current) {
          fileInputRef.current.files = null;
        }
        return;
      }
    }

    setStatus("UPLOADING");
    const res = await dispatch(uploadFile(files));

    if (!res) {
      setStatus("FAIL");
    } else {
      setStatus("SUCCESS");

      const pathStr =
        res.length > 1
          ? res.map((item) => item.filename).join(", ")
          : res[0].filename;
      setPath(pathStr);
    }

    onFinish(res);
  };

  const handleDeleteFile = () => {
    setStatus("INIT");
    setPath("");
    onFinish([]);
  };

  const VisuallyHiddenInput = styled("input")({
    inset: 0,
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    opacity: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
  });

  const statusDict = {
    INIT: {
      BtnText: "拖曳文件至此或點擊上傳檔案",
      BtnIcon: <CloudUploadIcon />,
      fn: null,
      color: "primary",
    },
    UPLOADING: {
      BtnText: "上傳中",
      BtnIcon: <CircularProgress size={16} />,
      fn: null,
      color: "inherit",
    },
    SUCCESS: {
      BtnText: "上傳成功",
      BtnIcon: null,
      color: "success",
    },
    FAIL: {
      BtnText: "上傳失敗",
      BtnIcon: null,
      color: "error",
    },
  };

  const getPositionStyle = () => {
    switch (position) {
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  };

  useEffect(() => {
    if (value && value.length > 0) {
      setStatus("SUCCESS");
      if (multiple) {
        const str = value.map((item) => item.filename).join(", ");
        setPath(str);
      } else {
        setPath(value[0].filename);
      }
    }
  }, [value]);

  return (
    <FormControl sx={{ width: "100%" }}>
      <Stack alignItems={getPositionStyle()}>
        <Stack flexDirection="row" gap={0.3}>
          {tooltip && (
            <Tooltip title={tooltip} placement="top-start">
              <ErrorOutlineIcon
                sx={{
                  fontSize: 16,
                  color: "#c0c0c0",
                }}
              />
            </Tooltip>
          )}
        </Stack>
        {status === "SUCCESS" && (
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography
              variant="h4"
              sx={{ m: 0, textAlign: position, fontWeight: 700 }}
            >
              {path.split("/").pop()}
            </Typography>
            <JumboIconButton onClick={handleDeleteFile}>
              <DeleteIcon />
            </JumboIconButton>
          </Stack>
        )}

        {status !== "SUCCESS" && (
          <Stack
            // disabled={status === "UPLOADING" || status === "SUCCESS"}
            // component="label"
            // variant="contained"
            // startIcon={statusDict[status].BtnIcon}
            // color={statusDict[status].color}
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{
              position: "relative",
              border: "1px dashed #c0c0c0",
              borderRadius: 1,
              px: 2,
              py: 6,
              width: "100%",
              textAlign: "center",
              height: "100%",
              cursor: "pointer",
            }}
          >
            {(status === "INIT" || status === "FAIL") && (
              <VisuallyHiddenInput
                ref={fileInputRef}
                multiple={multiple}
                type="file"
                onChange={handleFileChange}
              />
            )}
            {statusDict[status].BtnIcon}
            {statusDict[status].BtnText}
          </Stack>
        )}
      </Stack>
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default DragUploadSection;

DragUploadSection.propTypes = {
  position: PropTypes.oneOf(["left", "center", "right"]),
  multiple: PropTypes.bool,
  onFinish: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.array,
  supportType: PropTypes.array,
};
