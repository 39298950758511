import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const CheckboxSection = ({
  title,
  name,
  value,
  onChange,
  width,
  error,
  helperText,
  label,
  disabled,
  sx,
}) => {
  const handleChange = (e) => {
    onChange({
      target: {
        name,
        value: e.target.checked,
      },
    });
  };

  return (
    <Stack sx={{ width: width || "100%", mb: 3, ...sx }}>
      {title && (
        <Typography variant="h4" sx={{ mb: 2 }}>
          {title}
        </Typography>
      )}
      <FormControl fullWidth name={name}>
        <FormControlLabel
          control={<Checkbox size="medium" sx={{ px: 1, py: 0 }} />}
          disabled={disabled}
          label={label}
          checked={value}
          onChange={handleChange}
        />
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </FormControl>
    </Stack>
  );
};

export default CheckboxSection;

CheckboxSection.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  sx: PropTypes.object,
};
