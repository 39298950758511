import TaskPage from "app/pages/task";
import React from "react";

import Home from "../pages/home";
import adminRoutes from "./admin";
import authRoutes from "./auth";
import channelRoutes from "./channel";
import conversationRoutes from "./conversation";
import errorRoutes from "./error";
import knowledgebaseRoutes from "./knowledgebase";
import pluginRoutes from "./plugin";
import reportRoutes from "./report";

const homeRoutes = [
  {
    path: "/",
    element: <Home />,
  },
];

const taskRoutes = [
  {
    path: "/task",
    element: <TaskPage />,
  },
];

const routes = [
  ...homeRoutes,
  ...authRoutes,
  ...conversationRoutes,
  ...knowledgebaseRoutes,
  ...adminRoutes,
  ...pluginRoutes,
  ...taskRoutes,
  ...reportRoutes,
  ...channelRoutes,
  ...errorRoutes,
];

export default routes;
