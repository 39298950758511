import Div from "@jumbo/shared/Div";
import AddIcon from "@mui/icons-material/Add";
import ChatIcon from "@mui/icons-material/Chat";
import ExtensionIcon from "@mui/icons-material/Extension";
import LayersIcon from "@mui/icons-material/Layers";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import { Avatar, Button, IconButton, Stack, Typography } from "@mui/material";
import { userLogout } from "app/redux/actions/auth";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
function Information({ user }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleLogout = () => {
    dispatch(userLogout());
  };

  const isAdmin = user.role === "admin";

  return (
    <Div
      sx={{
        p: 3,
        py: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Avatar
        sx={{ width: 56, height: 56, mb: 2, fontSize: 34, bgcolor: "#32B5C9" }}
      >
        {user.username[0].toUpperCase()}
      </Avatar>
      <Typography variant="h4">{user.username}</Typography>
      <Typography variant="h5" sx={{ color: "#c0c0c0" }}>
        {user.role}
      </Typography>
      <Stack flexDirection="row" gap={2} mb={2}>
        <IconButton onClick={handleLogout} sx={{ fontSize: 18 }}>
          <LogoutIcon sx={{ fontSize: 18 }} />
        </IconButton>
        {isAdmin && pathname.includes("/admin") && (
          <IconButton sx={{ fontSize: 18 }} onClick={() => navigate("/")}>
            <ChatIcon sx={{ fontSize: 18 }} />
          </IconButton>
        )}
        {isAdmin && !pathname.includes("/admin") && (
          <IconButton
            sx={{ fontSize: 18 }}
            onClick={() => navigate("/admin/dashboard")}
          >
            <SettingsIcon sx={{ fontSize: 18 }} />
          </IconButton>
        )}
      </Stack>
      <Stack flexDirection="column" gap={1}>
        {/* <Button
          startIcon={<CreateIcon />}
          variant="contained"
          sx={{ borderRadius: 99 }}
          color="success"
          onClick={() => navigate("/report")}
        >
          撰寫報告
        </Button> */}
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          sx={{ borderRadius: 99 }}
          onClick={() => navigate("/create/conversation")}
        >
          新增對話
        </Button>
        <Button
          startIcon={<LayersIcon />}
          variant="contained"
          color="info"
          sx={{ borderRadius: 99 }}
          onClick={() => navigate("/knowledgebase")}
        >
          知識庫
        </Button>
        <Button
          startIcon={<ExtensionIcon fontSize="small" />}
          variant="contained"
          color="secondary"
          sx={{ borderRadius: 99 }}
          onClick={() => navigate("/plugins")}
        >
          智慧插件
        </Button>
      </Stack>
    </Div>
  );
}

export default Information;

Information.propTypes = {
  user: PropTypes.object,
};
