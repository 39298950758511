import http from "app/services";

import {
  UPD_FILE,
  UPD_FILE_DONE,
  UPD_IMAGE,
  UPD_IMAGE_DONE,
} from "../types/files";
import { infoAlert, statusHandler, successAlert } from "./alert";

export const uploadFile = (files) => async (dispatch) => {
  dispatch({ type: UPD_FILE });

  const formData = new FormData();
  Array.from(files).forEach((file) => formData.append("files", file));

  try {
    const res = await http.post("/files?namespace=files", formData);

    if (!res.data.success) {
      return false;
    }

    dispatch({ type: UPD_FILE_DONE });
    return res.data.payload;
  } catch (error) {
    dispatch(statusHandler(error.response?.status || 500));
    return false;
  }
};

export const uploadImage = (file) => {
  return async (dispatch) => {
    dispatch({ type: UPD_IMAGE });
    const res = await http.post("/files?namespace=images", file);
    dispatch(infoAlert("正在上傳圖片..."));
    if (!res.data.success) {
      dispatch(statusHandler(res.response?.status || 500));
      return false;
    }
    dispatch({ type: UPD_IMAGE_DONE });
    const image = new Image();
    image.src = res.data.payload;
    image.onload = () => {
      dispatch(successAlert("上傳圖片成功"));
      return res.data.payload;
    };
  };
};
