export const CREATE_CHANNEL = "CHANNEL/CREATE_CHANNEL";
export const CREATE_CHANNEL_DONE = "CHANNEL/CREATE_CHANNEL_DONE";

export const DEL_CHANNEL = "CHANNEL/DEL_CHANNEL";
export const DEL_CHANNEL_DONE = "CHANNEL/DEL_CHANNEL_DONE";

export const DEL_CONVERSATION = "CHANNEL/DEL_CONVERSATION";
export const DEL_CONVERSATION_DONE = "CHANNEL/DEL_CONVERSATION_DONE";

export const FCH_CHANNELS = "CHANNEL/FCH_CHANNELS";
export const FCH_CHANNELS_DONE = "CHANNEL/FCH_CHANNELS_DONE";

export const GET_CHANNEL = "CHANNEL/GET_CHANNEL";
export const GET_CHANNEL_DONE = "CHANNEL/GET_CHANNEL_DONE";
