import JumboIconButton from "@jumbo/components/JumboIconButton";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  delKnowledgebase,
  sortKnowledgebases,
} from "app/redux/actions/knowledgebase";
import { List } from "immutable";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import ConfirmModal from "../Modal/ConfirmModal";

const KnowledgebaseDataTable = ({ dataSource }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [knowledgebaseId, setKnowledgebaseId] = useState(null);
  const [nameSort, setNameSort] = useState("desc");
  const [createdAtSort, setCreatedAtSort] = useState("desc");

  const user_id = useSelector(({ app }) => app.getIn(["user", "id"]));

  const groupData = useSelector(({ group }) =>
    group.getIn(["results", "groups"]),
  );

  const handleDelModalOpen = (id) => {
    setKnowledgebaseId(id);
    setIsDelModalOpen(true);
  };

  const handleDelModalClose = () => {
    setKnowledgebaseId(null);
    setIsDelModalOpen(false);
  };

  const handleDeleteKnowledgebase = async () => {
    await dispatch(delKnowledgebase(knowledgebaseId, user_id));
    handleDelModalClose();
  };

  const findEmailById = (id) => {
    if (!groupData) return null;
    const group = groupData.find((group) => group.get("id") === id);
    return group ? group.get("email") : null;
  };

  const handleNameSort = (order) => {
    dispatch(sortKnowledgebases("name", order));
    setNameSort(order);
  };

  const handleCreatedAtSort = (order) => {
    dispatch(sortKnowledgebases("created_at", order));
    setCreatedAtSort(order);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                知識庫名稱{" "}
                {nameSort === "asc" ? (
                  <IconButton
                    onClick={() => handleNameSort("desc")}
                    sx={{ p: 0 }}
                  >
                    <ArrowUpwardIcon fontSize="small" sx={{ fontSize: 18 }} />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleNameSort("asc")}
                    sx={{ p: 0 }}
                  >
                    <ArrowDownwardIcon fontSize="small" sx={{ fontSize: 18 }} />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>建立者</TableCell>
              <TableCell>
                建立日期
                {createdAtSort === "asc" ? (
                  <IconButton
                    onClick={() => handleCreatedAtSort("desc")}
                    sx={{ p: 0, ml: 1 }}
                  >
                    <ArrowUpwardIcon fontSize="small" sx={{ fontSize: 18 }} />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleCreatedAtSort("asc")}
                    sx={{ p: 0, ml: 1 }}
                  >
                    <ArrowDownwardIcon fontSize="small" sx={{ fontSize: 18 }} />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>設定</TableCell>
              <TableCell>刪除</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {dataSource.map((knowledgebase) => (
              <TableRow key={knowledgebase.get("id")}>
                <TableCell>{knowledgebase.get("name")}</TableCell>
                <TableCell>
                  {findEmailById(knowledgebase.get("user_id"))}
                </TableCell>
                <TableCell>
                  {moment
                    .unix(knowledgebase.get("created_at"))
                    .format("YYYY/MM/DD HH:mm:ss")}
                </TableCell>
                <TableCell>
                  <JumboIconButton
                    sx={{ position: "relative", left: -5 }}
                    onClick={() =>
                      navigate(`/knowledgebase/${knowledgebase.get("id")}`)
                    }
                  >
                    <EditIcon color="primary" />
                  </JumboIconButton>
                </TableCell>
                <TableCell>
                  <JumboIconButton
                    sx={{ position: "relative", left: -5 }}
                    onClick={() => handleDelModalOpen(knowledgebase.get("id"))}
                  >
                    <DeleteIcon color="error" />
                  </JumboIconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmModal
        open={isDelModalOpen}
        content={
          <>
            <Typography variant="body1">確定要刪除此知識庫嗎？</Typography>
            <Typography variant="body2" color="error">
              此動作會造成所有關聯對話一併刪除。
            </Typography>
          </>
        }
        onClose={handleDelModalClose}
        onConfirm={handleDeleteKnowledgebase}
      />
    </>
  );
};

export default React.memo(KnowledgebaseDataTable);

KnowledgebaseDataTable.propTypes = {
  dataSource: PropTypes.instanceOf(List).isRequired,
};
