import Div from "@jumbo/shared/Div";
import Check from "@mui/icons-material/Check";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, MenuItem, Popover, Typography } from "@mui/material";
import { useEditor } from "novel";
import PropTypes from "prop-types";
import React from "react";
const TEXT_COLORS = [
  {
    name: "Default",
    color: "var(--novel-black)",
  },
  {
    name: "Purple",
    color: "#9333EA",
  },
  {
    name: "Red",
    color: "#E00000",
  },
  {
    name: "Yellow",
    color: "#EAB308",
  },
  {
    name: "Blue",
    color: "#2563EB",
  },
  {
    name: "Green",
    color: "#008A00",
  },
  {
    name: "Orange",
    color: "#FFA500",
  },
  {
    name: "Pink",
    color: "#BA4081",
  },
  {
    name: "Gray",
    color: "#A8A29E",
  },
];

const HIGHLIGHT_COLORS = [
  {
    name: "Default",
    color: "var(--novel-highlight-default)",
  },
  {
    name: "Purple",
    color: "var(--novel-highlight-purple)",
  },
  {
    name: "Red",
    color: "var(--novel-highlight-red)",
  },
  {
    name: "Yellow",
    color: "var(--novel-highlight-yellow)",
  },
  {
    name: "Blue",
    color: "var(--novel-highlight-blue)",
  },
  {
    name: "Green",
    color: "var(--novel-highlight-green)",
  },
  {
    name: "Orange",
    color: "var(--novel-highlight-orange)",
  },
  {
    name: "Pink",
    color: "var(--novel-highlight-pink)",
  },
  {
    name: "Gray",
    color: "var(--novel-highlight-gray)",
  },
];

const ColorSelector = ({ open, onOpenChange, onClose }) => {
  const { editor } = useEditor();
  const [anchorEl, setAnchorEl] = React.useState(null);

  if (!editor) return null;

  const activeColorItem = TEXT_COLORS.find(({ color }) =>
    editor.isActive("textStyle", { color }),
  );

  const activeHighlightItem = HIGHLIGHT_COLORS.find(({ color }) =>
    editor.isActive("highlight", { color }),
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onOpenChange(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose("color");
  };

  const handleColorSelect = (color, name) => {
    editor.commands.unsetColor();
    if (name !== "Default") {
      editor
        .chain()
        .focus()
        .setColor(color || "")
        .run();
    }
    handleClose();
  };

  const handleHighlightSelect = (color, name) => {
    editor.commands.unsetHighlight();
    if (name !== "Default") {
      editor.chain().focus().setHighlight({ color }).run();
    }
    handleClose();
  };

  return (
    <>
      <Button
        size="small"
        variant="text"
        onClick={handleClick}
        sx={{
          minWidth: "auto",
          gap: 1,
          borderRadius: 0,
        }}
        endIcon={
          <KeyboardArrowDown
            sx={{ width: 16, height: 16, color: activeColorItem?.color }}
          />
        }
      >
        <Div
          component="span"
          sx={{
            color: activeColorItem?.color || "#475259",
            bgcolor: activeHighlightItem?.color,
            width: 24,
            height: 24,
          }}
        >
          A
        </Div>
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            width: 200,
            maxHeight: 320,
            mt: 1,
            overflow: "auto",
          },
        }}
      >
        <Box sx={{ p: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{
              px: 1,
              py: 0.5,
              color: "text.secondary",
            }}
          >
            Color
          </Typography>

          {TEXT_COLORS.map(({ name, color }) => (
            <MenuItem
              key={name}
              onClick={() => handleColorSelect(color, name)}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  px: 1,
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: "2px",
                  color: color,
                }}
              >
                A
              </Box>
              <Typography variant="body2">{name}</Typography>
            </MenuItem>
          ))}

          <Typography
            variant="subtitle2"
            sx={{
              px: 1,
              py: 0.5,
              mt: 1,
              color: "text.secondary",
            }}
          >
            Background
          </Typography>

          {HIGHLIGHT_COLORS.map(({ name, color }) => (
            <MenuItem
              key={name}
              onClick={() => handleHighlightSelect(color, name)}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
                py: 0.5,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box
                  sx={{
                    px: 1,
                    border: "1px solid",
                    borderColor: "divider",
                    borderRadius: "2px",
                    bgcolor: color,
                  }}
                >
                  A
                </Box>
                <Typography variant="body2">{name}</Typography>
              </Box>
              {editor.isActive("highlight", { color }) && (
                <Check sx={{ width: 16, height: 16 }} />
              )}
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default ColorSelector;

ColorSelector.propTypes = {
  open: PropTypes.bool.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
