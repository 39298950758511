import Immutable from "immutable";

import {
  CREATE_CHANNEL_DONE,
  DEL_CHANNEL_DONE,
  DEL_CONVERSATION_DONE,
  FCH_CHANNELS_DONE,
  GET_CHANNEL_DONE,
} from "../types/channel";

const INIT_STATE = Immutable.OrderedMap({
  results: Immutable.OrderedMap({}),
});

const channelReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FCH_CHANNELS_DONE: {
      const docs = Immutable.fromJS(payload.doc)
        .map((doc) => Immutable.OrderedMap().set(doc.get("id"), doc))
        .reduce((acc, doc) => acc.merge(doc), Immutable.OrderedMap());

      return _.set("results", docs);
    }
    case DEL_CHANNEL_DONE: {
      return _.deleteIn(["results", payload.id]);
    }
    case CREATE_CHANNEL_DONE: {
      return _.setIn(["results", payload.id], Immutable.fromJS(payload));
    }
    case GET_CHANNEL_DONE: {
      return _.setIn(["results", payload.id], Immutable.fromJS(payload));
    }
    case DEL_CONVERSATION_DONE: {
      const convId = payload.conversation_id;
      return _.updateIn(
        ["results", payload.channel_id, "conversations"],
        (conversations) =>
          conversations.filter((conv) => conv.get("id") !== convId),
      );
    }
    default:
      return _;
  }
};

export default channelReducer;
