import { Button, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import { generateReport } from "app/redux/actions/report";
import Editor from "app/shared/Editor";
import CheckboxSection from "app/shared/Form/CheckboxSection";
import DragUploadSection from "app/shared/Form/DragUploadSection";
import InputSection from "app/shared/Form/InputSection";
import SelectSection from "app/shared/Form/SelectSection";
import SliderSection from "app/shared/Form/SliderSection";
import Loading from "app/shared/Loading";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

const initialValues = {
  knowledge_base_ids: [],
  is_search_internet: false,
  is_include_directory: false,
  is_include_summary: false,
  language_model: "",
  temperature: 0.5,
  max_tokens: 2048,
  rfp_file_path: "",
  exemplar_file_path: "",
  title: "",
};

const ReportPage = () => {
  const dispatch = useDispatch();

  const [tokenMarks, setTokenMarks] = useState([
    {
      value: 1,
      label: "1",
    },
    {
      value: 4096,
      label: "4096",
    },
  ]);

  const knowledgebaseList = useSelector(({ knowledgebase }) =>
    knowledgebase.getIn(["results", "knowledgebases"]),
  );

  const modelList = useSelector(({ model }) =>
    model.getIn(["results", "models"]),
  );

  const uId = useSelector(({ app }) => app.getIn(["user", "id"]));

  const validationSchema = yup.object({
    title: yup.string().required("請輸入報告標題"),
    // knowledge_base_ids: yup.array().min(1, "請至少選擇一個知識庫"),
    language_model: yup.object().required("請選擇模型"),
    rfp_file_path: yup.array().min(1, "請上傳檔案").required("請上傳檔案"),
    exemplar_file_path: yup.array().min(1, "請上傳檔案").required("請上傳檔案"),
    temperature: yup.number().required("請設定活潑度"),
    max_tokens: yup.number().required("請設定最大 Token 長度"),
  });

  const handleGenerateReport = async (values) => {
    const data = {
      title: values.title,
      rfp_file_path: values.rfp_file_path[0].path,
      exemplar_file_path: values.exemplar_file_path[0].path,
      model_name: values.language_model.value,
      temperature: values.temperature,
      max_tokens: values.max_tokens,
    };

    await dispatch(generateReport(uId, data));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleGenerateReport,
  });

  const handleChangeModel = (e) => {
    const value = e.target.value;
    formik.setFieldValue("language_model", value);

    const model = modelList.find((model) => model.get("id") === value.value);

    if (model) {
      tokenMarks[1].value = Number(model.get("max_tokens"));
      tokenMarks[1].label = model.get("max_tokens");
      const maxTokens = model.get("max_tokens") / 2;
      formik.setFieldValue("max_tokens", maxTokens);
    }
    setTokenMarks(tokenMarks);
  };

  const handleUploadFile = (files, field) => {
    formik.setFieldValue(field, files);
  };

  useEffect(() => {
    if (modelList) {
      const model = modelList.getIn([0, "name"]);
      handleChangeModel({ target: { value: model } });
      formik.setFieldValue("language_model", { value: model, label: model });
    }
  }, [modelList]);

  if (!knowledgebaseList || !modelList) return <Loading />;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Card sx={{ p: 4 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography variant="h1" sx={{ m: 0 }}>
                撰寫報告
              </Typography>
              <Stack direction="row" spacing={1}>
                <Button variant="contained" color="inherit">
                  儲存草稿
                </Button>
                <Button variant="contained" color="info">
                  產生大綱
                </Button>
                <Button
                  variant="contained"
                  onClick={formik.handleSubmit}
                  color="success"
                >
                  產生報告
                </Button>
                <Button variant="contained" color="secondary">
                  下載報告
                </Button>
              </Stack>
            </Stack>
            <Divider sx={{ my: 4 }} />
            <InputSection
              title="報告標題"
              width="100%"
              size="small"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
            <Editor />
          </Card>
        </Grid>
        <Grid gap={2} container item xs={4} sx={{ height: "fit-content" }}>
          <Grid item xs={12}>
            <Card sx={{ p: 4 }}>
              <Typography variant="h4" sx={{ mb: 2 }}>
                報告需求
              </Typography>
              <DragUploadSection
                showPath
                onFinish={(files) => handleUploadFile(files, "rfp_file_path")}
                error={
                  formik.touched.rfp_file_path &&
                  Boolean(formik.errors.rfp_file_path)
                }
                helperText={
                  formik.touched.rfp_file_path && formik.errors.rfp_file_path
                }
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ p: 4 }}>
              <Typography variant="h4" sx={{ mb: 2 }}>
                參考報告
              </Typography>
              <DragUploadSection
                showPath
                onFinish={(files) =>
                  handleUploadFile(files, "exemplar_file_path")
                }
                error={
                  formik.touched.exemplar_file_path &&
                  Boolean(formik.errors.exemplar_file_path)
                }
                helperText={
                  formik.touched.exemplar_file_path &&
                  formik.errors.exemplar_file_path
                }
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ p: 4 }}>
              <SelectSection
                width="100%"
                title="知識庫"
                name="knowledge_base_ids"
                defaultOptionLabel="選擇知識庫"
                sx={{ mb: 1 }}
                value={formik.values.knowledge_base_ids}
                onChange={(e) =>
                  formik.setFieldValue("knowledge_base_ids", e.target.value)
                }
                groupBy={(option) => option.groupBy}
                multiple
                options={knowledgebaseList
                  .map((item) => ({
                    value: item.get("id"),
                    label: item.get("name"),
                  }))
                  .toJS()}
                error={
                  formik.touched.knowledge_base_ids &&
                  Boolean(formik.errors.knowledge_base_ids)
                }
                helperText={
                  formik.touched.knowledge_base_ids &&
                  formik.errors.knowledge_base_ids
                }
                disabled={formik.values.conversation_mode === "language_model"}
              />
              <CheckboxSection
                name="is_search_internet"
                value={formik.values.is_search_internet}
                sx={{ mb: 0 }}
                onChange={formik.handleChange}
                label="允許搜尋網路資料"
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ p: 4 }}>
              <SelectSection
                width="100%"
                title="報告風格"
                name="report_style"
                defaultOptionLabel="選擇報告風格"
                sx={{ mb: 1 }}
                value={formik.values.report_style}
                onChange={(e) =>
                  formik.setFieldValue("report_style", e.target.value)
                }
                options={[{ label: "報告風格", value: "report_style" }]}
                error={
                  formik.touched.report_style &&
                  Boolean(formik.errors.report_style)
                }
                helperText={
                  formik.touched.report_style && formik.errors.report_style
                }
              />
              <CheckboxSection
                name="is_include_directory"
                value={formik.values.is_include_directory}
                sx={{ mb: 0 }}
                onChange={formik.handleChange}
                label="包含目錄"
              />
              <CheckboxSection
                name="is_include_summary"
                value={formik.values.is_include_summary}
                sx={{ mb: 0 }}
                onChange={formik.handleChange}
                label="包含執行摘要"
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ p: 4 }}>
              <SelectSection
                width="100%"
                title="模型設定"
                name="language_model"
                defaultOptionLabel="選擇模型"
                sx={{ mb: 1 }}
                value={formik.values.language_model}
                onChange={handleChangeModel}
                options={modelList
                  .map((item) => ({
                    value: item.get("name"),
                    label: item.get("name"),
                  }))
                  .toJS()}
                error={
                  formik.touched.language_model &&
                  Boolean(formik.errors.language_model)
                }
                helperText={
                  formik.touched.language_model && formik.errors.language_model
                }
              />
              <SliderSection
                width="100%"
                title="活潑度(溫度)"
                name="temperature"
                tooltip="Temperature 數字越高 代表 回答的創造性 越高; 反之 則越精確"
                marks={tempMarks}
                step={0.1}
                value={formik.values.temperature}
                onChange={formik.handleChange}
              />

              <SliderSection
                width="100%"
                title="最大 Token 長度"
                name="max_tokens"
                tooltip="Token長度越多，回覆長度越長"
                marks={tokenMarks}
                step={1}
                value={formik.values.max_tokens}
                onChange={formik.handleChange}
              />
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReportPage;

const tempMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
];
