import { Card, Divider, Stack, Typography } from "@mui/material";
import { fchChannels } from "app/redux/actions/channel";
import ChannelDataTable from "app/shared/DataTable/ChannelDataTable";
import CreateChannel from "app/shared/Modal/CreateChannel";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const ChannelsPage = () => {
  const dispatch = useDispatch();

  const user_id = useSelector(({ app }) => app.getIn(["user", "id"]));
  useEffect(() => {
    if (user_id) {
      dispatch(fchChannels(user_id));
    }
  }, [user_id]);

  const channels = useSelector(({ channel }) => channel.get("results"));

  return (
    <Card sx={{ p: 4 }}>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3" sx={{ m: 0 }}>
          聊天機器人管理
        </Typography>
        <Stack flexDirection="row" gap={2}>
          <CreateChannel uId={user_id} />
        </Stack>
      </Stack>

      <Divider sx={{ my: 4 }} />

      <ChannelDataTable dataSource={channels.valueSeq()} />
    </Card>
  );
};

export default ChannelsPage;
